// import { Parallax } from 'react-scroll-parallax';
import Styles from './Parallax.module.scss';

const RightTriangle = () => {
  return (
    <div className={Styles.parallax}>
      <div className={Styles.group}>
        <div className={Styles.smallRightTriangle} />
        <div className={Styles.largeRightTriangle} />
      </div>
    </div>
  );
};

export default RightTriangle;
